<template>
  <div class="cohort-list d-flex flex-column px-8 px-md-10 py-8">
    <v-row :class="{ 'd-none': type === 'groups' }">
      <v-tabs v-model="tab" color="secondary">
        <v-tabs-slider color="primary" />
        <v-tab v-for="{ id, name } in tabs" :key="id" class="text-capitalize" v-text="name" />
      </v-tabs>
      <v-divider style="border-right: 1px solid;" class="mb-4" />
    </v-row>
    <v-row class="justify-sm-end align-center mb-4">
      <v-text-field
        v-model="search"
        class="flex-sm-grow-0 mb-4 mb-sm-0"
        :label="$t('common.search')"
        prepend-inner-icon="search"
        tag="span"
        outlined
        dense
        hide-details
      />
      <export-to-csv
        v-if="type === 'matches'"
        :file-name-preffix="`${project.name} - ${$t(`competition.sections.matches`)}`"
        action-name="competition/getMatchesCsv"
        :action-params="{ organizationId, projectId }"
      />
      <export-to-csv
        v-if="type === 'ranking'"
        :file-name-preffix="`${project.name} - ${$t(`competition.sections.ranking`)}`"
        action-name="competition/getRrobinStatsCsv"
        :action-params="{ organizationId, projectId }"
      />
    </v-row>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ id, items } in tabs" :key="id">
        <!-- Table -->
        <v-data-table v-if="items.length"
                      :headers="getHeaders(id)" :items="items"
                      :search="search" dense
                      class="mt-6 table--has-pointer" @click:row="item => goToDetail({ filterType: id, entityId: item.id, })"
        >
          <template #[`item.status`]="{ item }">
            <span class="pa-0 cohort-list__item" :style="{ color: getColor(item.status)}" v-text="$t(`project.competition.cohorts.status.${item.status}`)" />
          </template>
          <template #[`item.active`]="{ item }">
            <v-chip
              v-if="item.active"
              outlined label color="success" style="min-width: 5rem;"
            >
              {{ $t('project.competition.groups.matchsCreated') }}
            </v-chip>
          </template>
        </v-data-table>
        <empty-list v-else :content="$t('competition.sections.cohorts')" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FilterList',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
    ExportToCsv: () => import('@/components/ExportToCsv'),
  },
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    type: { type: String, required: true },
  },
  data() {
    return {
      tab: null,
      search: '',
      sortBy: 'name',
      sortDesc: false,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters('project', ['hasMatchLocationsFeature']),
    ...mapGetters({ rawCohorts: 'competition/cohorts' }),
    ...mapGetters({ rawClubs: 'competition/clubs' }),
    ...mapGetters('competition', ['matches', 'matchLocations']),
    formattedCohorts({ rawCohorts }) {
      return rawCohorts.filter(cohort => cohort.active).map(cohort => ({
        id: cohort.id,
        name: cohort.name,
        status: this.matches?.filter(m => m.cohortId === cohort.id)?.every(match => match?.date) ? 'completed' : 'unCompleted',
      }))
    },
    formattedClubs({ rawClubs }) {
      return rawClubs.map(c => ({
        id: c.id,
        name: c.name,
        status: this.matches?.filter(m => m.clubIds?.includes(c.id))?.every(m => m?.date) ? 'completed' : 'unCompleted',
      }))
    },
    formattedGroups({ rawCohorts }) {
      return rawCohorts.map(c => ({
        id: c.id,
        name: c.name,
        groups: c.dataByStageId?.rrobin?.groupCount,
        teamsPerGroup: c.dataByStageId?.rrobin?.teamsPerGroup,
        active: c.active,
      }))
    },
    cohortHeaders() {
      return [
        { text: this.$t('project.competition.type.categories'), value: 'name' },
        ...(this.type === 'matches' ? [{ text: this.$t('project.competition.cohorts.status.info'), value: 'status' }] : []),
      ]
    },
    locationHeaders() {
      return [
        { text: this.$t('project.competition.matchLocations.editMatchLocation.name'), value: 'name' },
        { text: this.$t('common.address'), value: 'address' },
      ]
    },
    clubsHeaders() {
      return [
        { text: this.$t('club.fields.name'), value: 'name' },
        { text: this.$t('project.competition.cohorts.status.info'), value: 'status' },
      ]
    },
    groupsHeaders() {
      return [
        { text: this.$t('project.competition.type.categories'), value: 'name' },
        { text: this.$t('project.sections.groups'), value: 'groups' },
        { text: this.$t('project.competition.groups.teamsPerGroup'), value: 'teamsPerGroup' },
        { text: this.$t('project.competition.groups.stateGroups'), value: 'active', cellClass: 'py-4' },
      ]
    },
    tabs() {
      return [
        ...(this.type !== 'groups' ? [{ id: 'cohort', name: this.$t('common.byCategory'), items: this.formattedCohorts }] : []),
        ...((this.type === 'matches' && this.hasMatchLocationsFeature)
          ? [{ id: 'location', name: this.$t('common.byMatchLocation'), items: this.matchLocations }] : []
        ),
        ...(this.type === 'matches' ? [{ id: 'club', name: this.$t('common.byClub'), items: this.formattedClubs }] : []),
        ...(this.type === 'groups' ? [{ id: 'groups', name: this.$t('common.byCategory'), items: this.formattedGroups }] : []),
      ]
    },
  },
  created() {
    this.tab = this.type === 'groups' ? 'groups' : 'cohort'
  },
  methods: {
    // Helpers
    getColor(status) {
      if (status === 'unCompleted') return '#ff8f45'
      if (status === 'completed') return '#89d184'
      return 'red'
    },
    getHeaders(content) {
      return {
        cohort: this.cohortHeaders,
        location: this.locationHeaders,
        club: this.clubsHeaders,
        groups: this.groupsHeaders,
      }[content]
    },
    goToDetail({ filterType, entityId }) {
      if (this.type === 'ranking' && filterType === 'cohort') {
        return this.$router.push({ name: 'competition-ranking-by-cohort', params: { cohortId: entityId } })
      }
      if (this.type === 'matches' && filterType === 'cohort') {
        return this.$router.push({ name: 'competition-matches-by-cohort', params: { cohortId: entityId } })
      }
      if (this.type === 'matches' && filterType === 'club') {
        return this.$router.push({ name: 'competition-matches-by-club', params: { clubId: entityId } })
      }
      if (this.type === 'matches' && filterType === 'location') {
        return this.$router.push({ name: 'competition-matches-by-location', params: { locationId: entityId } })
      }
      if (this.type === 'groups') {
        return this.$router.push({ name: 'competition-create-cohort-groups', params: { cohortId: entityId } })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

::v-deep .v-slide-group:not(.v-slide-group--has-affixes) > .v-slide-group__prev {
  display: none !important;
}
</style>
